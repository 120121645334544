.badge-photo.size-medium {
  width: 6rem;
  height: 6rem;
}

.badge-photo.badge-photo-border {
  border: 2px solid var(--color-border-divider-default-cr3oi6, #eaeded);
}

.badge-photo.badge-photo-circle {
  border-radius: 20%;
}

.badge-photo {
  border: none;
  overflow: hidden;
}

.badge-photo img {
  object-fit: cover;
}

.badge-photo img, .badge-photo svg {
  width: 100%;
  height: 100%;
}