// Custom CSS to break long words

@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.root {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    border-top: 1px solid awsui.$color-border-divider-default;
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.custom-wrapping {
  word-break: break-all;
}

