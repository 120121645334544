.at-demo {
    background-color: #fff;
    .at-item, .pound-item {
        color: #2ba6cb;
    }
}
.at-item {
  color: #2ba6cb;
}
.tribute-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    display: block;
    z-index: 999999;
    max-height:280px
  }
  .tribute-container ul {
    margin-left: 20px;
    margin-top: 2px;
    font-size: 21px;
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 4px;
  }
  .tribute-container ul::-webkit-scrollbar {
	display: none;
  }
  .tribute-container li {
    padding: 5px 5px;
    cursor: pointer;
    color: #2ba6cb;
  }
  .tribute-container li.highlight {
    background: #eee;
  }
  .tribute-container li span {
    font-weight: bold;
  }
  .tribute-container li.no-match {
    cursor: default;
  }
  .tribute-container .menu-highlighted {
    font-weight: bold;
}
.tribute-demo-input {
  outline: none;
  border: 2px solid #7d8998;
  padding: 8px 11px;
  border-radius: 10px;
  font-size: 15px;
  min-height: 100px;
  cursor: text;
}
.tribute-demo-input:hover {
  border-color: #3c98fa;
  transition: all 0.3s;
}
.tribute-demo-input:focus {
  border-color: #3c98fa;
}
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #ccc;
}
#test-autocomplete-container {
  position: relative;
}
#test-autocomplete-textarea-container {
  position: relative;
}
.float-right {
  float: right;
}

.css-user-pic {
  width: 48px;
  height: 48px;
  position: relative;
  border-color: rgb(85, 37, 131);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.css-user-detail {
  width: 88px;
  height: 88px;
  position: relative;
  border-color: rgb(85, 37, 131);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.css-user-icon {
  width: 88px;
  height: 88px;
  border-color: rgb(85, 37, 131);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  background-size: cover;
   background-repeat: no-repeat;
}

.css-user-token {
  width: 36px;
  height: 48px;
  border-color: rgb(85, 37, 131);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  background-size: cover;
   background-repeat: no-repeat;
}
.am-font{
  margin-left: 10px;
  border-collapse: separate;
    border-spacing: 0;
    caption-side: top;
    cursor: auto;
    direction: ltr;
    empty-cells: show;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    -webkit-hyphens: none;
    hyphens: none;
    letter-spacing: normal;
    list-style: disc outside none;
    tab-size: 8;
    text-align: left;
    text-align-last: auto;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    widows: 2;
    word-spacing: normal;
    box-sizing: border-box;
    font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
}
