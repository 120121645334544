.react-mentions__input {
  line-height: normal;
}

.react-mentions.error textarea {
  border: 1px solid rgb(209, 50, 18) !important;
  border-left: 4px solid rgb(209, 50, 18) !important;
}
/*
Animated skeleton screen using CSS.
*/
.mentions-card-skeleton {
  height: 28px;
  display: flex;
  align-items: center;
}

.mentions-card-skeleton > div {
  margin: auto;
  width: 150px;
  height: 21px;

  background-image:
    linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ), /*highlight */
    linear-gradient( lightgray 6px, transparent 0 ),
    linear-gradient( lightgray 4px, transparent 0 );

  background-size:
    30% 100%, /* highlight */
    100%,
    100%;

  background-position:
    0 0, /* highlight */
    0px 0,
    0px 15px;

  animation: shine 1.1s infinite;
}

/* Animating one layer of the background which is a tilted linear gradient with white in the middle to achieve shine effect. */
@keyframes shine {
  to {
    background-position:
      100% 0, /* move highlight to right */
      0px 0,
      0px 15px;
  }
}

.react-mentions.isLoading .react-mentions__suggestions__item--focused {
  background-color: #FFF !important;
  pointer-events: none;
}